import { CountryList } from './model';

export const countriesList: CountryList = {
    Afghanistan: { zone: '', states: [] },
    Albania: { zone: '', states: [] },
    Algeria: { zone: '', states: [] },
    'American Samoa': { zone: '', states: [] },
    Andorra: { zone: '', states: [] },
    Angola: { zone: '', states: [] },
    Anguilla: { zone: '', states: [] },
    Antarctica: { zone: '', states: [] },
    'Antigua And Barbuda': { zone: '', states: [] },
    Argentina: { zone: '', states: [] },
    Armenia: { zone: '', states: [] },
    Aruba: { zone: '', states: [] },
    Australia: {
      zone: '',
      states: [
        { id: 'ACT', val: 'Australian Capital Territory' },
        { id: 'NSW', val: 'New South Wales' },
        { id: 'NTT', val: 'Northern Territory' },
        { id: 'QLD', val: 'Queensland' },
        { id: 'SA', val: 'South Australia' },
        { id: 'TAS', val: 'Tasmania' },
        { id: 'VIC', val: 'Victoria' },
        { id: 'WAU', val: 'Western Australia' }
      ]
    },
    Austria: { zone: 'EU', states: [] },
    Azerbaijan: { zone: '', states: [] },
    Bahamas: { zone: '', states: [] },
    Bahrain: { zone: '', states: [] },
    Bangladesh: { zone: '', states: [] },
    Barbados: { zone: '', states: [] },
    Belgium: { zone: 'EU', states: [] },
    Belize: { zone: '', states: [] },
    Benin: { zone: '', states: [] },
    Bermuda: { zone: '', states: [] },
    Bhutan: { zone: '', states: [] },
    Bolivia: { zone: '', states: [] },
    'Bonaire, Sint Eustatius and Saba': { zone: '', states: [] },
    'Bosnia And Herzegovina': { zone: '', states: [] },
    Botswana: { zone: '', states: [] },
    'Bouvet Island': { zone: '', states: [] },
    Brazil: {
      zone: '',
      states: [
        { id: 'ACRE', val: 'Acre' },
        { id: 'ALBS', val: 'Alagoas' },
        { id: 'AMPA', val: 'Amapá' },
        { id: 'AMZN', val: 'Amazonas' },
        { id: 'BHIA', val: 'Bahia' },
        { id: 'CARA', val: 'Ceará' },
        { id: 'DFRL', val: 'Distrito Federal' },
        { id: 'ESNT', val: 'Espírito Santo' },
        { id: 'GOIS', val: 'Goiás' },
        { id: 'MARH', val: 'Maranhão' },
        { id: 'MGSS', val: 'Mato Grosso' },
        { id: 'MGDS', val: 'Mato Grosso do Sul' },
        { id: 'MNSG', val: 'Minas Gerais' },
        { id: 'PRNA', val: 'Paraná' },
        { id: 'PRBA', val: 'Paraíba' },
        { id: 'PARA', val: 'Pará' },
        { id: 'PRNB', val: 'Pernambuco' },
        { id: 'PIUI', val: 'Piauí' },
        { id: 'RGDN', val: 'Rio Grande do Norte' },
        { id: 'RGDS', val: 'Rio Grande do Sul' },
        { id: 'RDJN', val: 'Rio de Janeiro' },
        { id: 'RNDA', val: 'Rondônia' },
        { id: 'RORM', val: 'Roraima' },
        { id: 'SCRN', val: 'Santa Catarina' },
        { id: 'SRGP', val: 'Sergipe' },
        { id: 'SPLO', val: 'São Paulo' },
        { id: 'TOCN', val: 'Tocantins' }
      ]
    },
    'British Indian Ocean Territory': { zone: '', states: [] },
    Brunei: { zone: '', states: [] },
    Bulgaria: { zone: 'EU', states: [] },
    'Burkina Faso': { zone: '', states: [] },
    Burundi: { zone: '', states: [] },
    Cambodia: { zone: '', states: [] },
    Cameroon: { zone: '', states: [] },
    Canada: {
      zone: '',
      states: [
        { id: 'AB', val: 'Alberta' },
        { id: 'BC', val: 'British Columbia' },
        { id: 'MB', val: 'Manitoba' },
        { id: 'NB', val: 'New Brunswick' },
        { id: 'NL', val: 'Newfoundland and Labrador' },
        { id: 'NT', val: 'Northwest Territories' },
        { id: 'NS', val: 'Nova Scotia' },
        { id: 'NU', val: 'Nunavut' },
        { id: 'ON', val: 'Ontario' },
        { id: 'PE', val: 'Prince Edward Island' },
        { id: 'QC', val: 'Quebec' },
        { id: 'SK', val: 'Saskatchewan' },
        { id: 'YT', val: 'Yukon' }
      ]
    },
    'Cape Verde': { zone: '', states: [] },
    'Cayman Islands': { zone: '', states: [] },
    'Central African Republic': { zone: '', states: [] },
    Chad: { zone: '', states: [] },
    Chile: { zone: '', states: [] },
    China: { zone: '', states: [] },
    'Christmas Island': { zone: '', states: [] },
    'Cocos (Keeling) Islands': { zone: '', states: [] },
    Colombia: { zone: '', states: [] },
    Comoros: { zone: '', states: [] },
    Congo: { zone: '', states: [] },
    'Cook Islands': { zone: '', states: [] },
    'Costa Rica': { zone: '', states: [] },
    'Côte d\'Ivoire': { zone: '', states: [] },
    Croatia: { zone: 'EU', states: [] },
    Curaçao: { zone: '', states: [] },
    Cyprus: { zone: 'EU', states: [] },
    'Czech Republic': { zone: 'EU', states: [] },
    'Democratic Republic of the Congo': { zone: '', states: [] },
    Denmark: { zone: 'EU', states: [] },
    Djibouti: { zone: '', states: [] },
    Dominica: { zone: '', states: [] },
    'Dominican Republic': { zone: '', states: [] },
    Ecuador: { zone: '', states: [] },
    Egypt: { zone: '', states: [] },
    'El Salvador': { zone: '', states: [] },
    'Equatorial Guinea': { zone: '', states: [] },
    Eritrea: { zone: '', states: [] },
    Estonia: { zone: 'EU', states: [] },
    Eswatini: { zone: '', states: [] },
    Ethiopia: { zone: '', states: [] },
    'Faroe Islands': { zone: '', states: [] },
    'Falkland Islands (Malvinas)': { zone: '', states: [] },
    Fiji: { zone: '', states: [] },
    Finland: { zone: 'EU', states: [] },
    France: { zone: 'EU', states: [] },
    'French Guiana': { zone: '', states: [] },
    'French Polynesia': { zone: '', states: [] },
    'French Southern Territories': { zone: '', states: [] },
    Gabon: { zone: '', states: [] },
    Gambia: { zone: '', states: [] },
    Georgia: { zone: '', states: [] },
    Germany: { zone: 'EU', states: [] },
    Ghana: { zone: '', states: [] },
    Gibraltar: { zone: '', states: [] },
    Greece: { zone: 'EU', states: [] },
    Greenland: { zone: '', states: [] },
    Grenada: { zone: '', states: [] },
    Guadeloupe: { zone: '', states: [] },
    Guam: { zone: '', states: [] },
    Guatemala: { zone: '', states: [] },
    'Guernsey, C.I.': { zone: '', states: [] },
    Guinea: { zone: '', states: [] },
    'Guinea-Bissau': { zone: '', states: [] },
    Guyana: { zone: '', states: [] },
    Haiti: { zone: '', states: [] },
    'Heard And Mcdonald Islands': { zone: '', states: [] },
    'Holy See (the)': { zone: '', states: [] },
    Honduras: { zone: '', states: [] },
    'Hong Kong': { zone: '', states: [] },
    Hungary: { zone: 'EU', states: [] },
    Iceland: { zone: 'EU', states: [] },
    India: { zone: '', states: [] },
    Indonesia: { zone: '', states: [] },
    Iraq: { zone: '', states: [] },
    Ireland: { zone: 'EU', states: [] },
    'Isle Of Man': { zone: '', states: [] },
    Israel: { zone: '', states: [] },
    Italy: { zone: 'EU', states: [] },
    Jamaica: { zone: '', states: [] },
    Japan: { zone: '', states: [] },
    'Jersey, C.I.': { zone: '', states: [] },
    Jordan: { zone: '', states: [] },
    Kazakhstan: { zone: '', states: [] },
    Kenya: { zone: '', states: [] },
    Kiribati: { zone: '', states: [] },
    Kuwait: { zone: '', states: [] },
    Kyrgyzstan: { zone: '', states: [] },
    Laos: { zone: '', states: [] },
    Latvia: { zone: 'EU', states: [] },
    Lebanon: { zone: '', states: [] },
    Lesotho: { zone: '', states: [] },
    Liberia: { zone: '', states: [] },
    'Libyan Arab Jamahiriya': { zone: '', states: [] },
    Liechtenstein: { zone: 'EU', states: [] },
    Lithuania: { zone: 'EU', states: [] },
    Luxembourg: { zone: 'EU', states: [] },
    Macedonia: { zone: '', states: [] },
    Madagascar: { zone: '', states: [] },
    Malawi: { zone: '', states: [] },
    Malaysia: { zone: '', states: [] },
    Maldives: { zone: '', states: [] },
    Mali: { zone: '', states: [] },
    Malta: { zone: 'EU', states: [] },
    'Marshall Islands': { zone: '', states: [] },
    Martinique: { zone: '', states: [] },
    Mauritania: { zone: '', states: [] },
    Mauritius: { zone: '', states: [] },
    Mayotte: { zone: '', states: [] },
    Mexico: { zone: '', states: [] },
    Micronesia: { zone: '', states: [] },
    Moldova: { zone: '', states: [] },
    Monaco: { zone: '', states: [] },
    Mongolia: { zone: '', states: [] },
    Montenegro: { zone: '', states: [] },
    Montserrat: { zone: '', states: [] },
    Morocco: { zone: '', states: [] },
    Mozambique: { zone: '', states: [] },
    Myanmar: { zone: '', states: [] },
    Namibia: { zone: '', states: [] },
    Nauru: { zone: '', states: [] },
    Nepal: { zone: '', states: [] },
    Netherlands: { zone: 'EU', states: [] },
    'New Caledonia': { zone: '', states: [] },
    'New Zealand': { zone: '', states: [] },
    Nicaragua: { zone: '', states: [] },
    Niger: { zone: '', states: [] },
    Nigeria: { zone: '', states: [] },
    Niue: { zone: '', states: [] },
    'Norfolk Island': { zone: '', states: [] },
    'Northern Mariana Islands': { zone: '', states: [] },
    Norway: { zone: 'EU', states: [] },
    Oman: { zone: '', states: [] },
    Pakistan: { zone: '', states: [] },
    Palau: { zone: '', states: [] },
    Palestine: { zone: '', states: [] },
    Panama: { zone: '', states: [] },
    'Papua New Guinea': { zone: '', states: [] },
    Paraguay: { zone: '', states: [] },
    Peru: { zone: '', states: [] },
    Philippines: { zone: '', states: [] },
    Pitcairn: { zone: '', states: [] },
    Poland: { zone: 'EU', states: [] },
    Portugal: { zone: 'EU', states: [] },
    'Puerto Rico': { zone: '', states: [] },
    Qatar: { zone: '', states: [] },
    Reunion: { zone: '', states: [] },
    Romania: { zone: 'EU', states: [] },
    Rwanda: { zone: '', states: [] },
    'Saint Barthélemy': { zone: '', states: [] },
    'Saint Kitts And Nevis': { zone: '', states: [] },
    'Saint Lucia': { zone: '', states: [] },
    'Saint Martin (French part)': { zone: '', states: [] },
    Samoa: { zone: '', states: [] },
    'San Marino': { zone: '', states: [] },
    'Sao Tome And Principe': { zone: '', states: [] },
    'Saudi Arabia': { zone: '', states: [] },
    Senegal: { zone: '', states: [] },
    Serbia: { zone: '', states: [] },
    Seychelles: { zone: '', states: [] },
    'Sierra Leone': { zone: '', states: [] },
    Singapore: { zone: '', states: [] },
    'Sint Maarten (Dutch part)': { zone: '', states: [] },
    Slovakia: { zone: 'EU', states: [] },
    Slovenia: { zone: 'EU', states: [] },
    'Solomon Islands': { zone: '', states: [] },
    Somalia: { zone: '', states: [] },
    'South Africa': { zone: '', states: [] },
    'South Georgia and the South Sandwich Islands': { zone: '', states: [] },
    'South Korea': { zone: '', states: [] },
    'South Sudan': { zone: '', states: [] },
    Spain: {
      zone: 'EU',
      states: [
        { id: 'ANDL', val: 'Andalucia' },
        { id: 'ARGN', val: 'Aragon' },
        { id: 'ASTR', val: 'Asturias' },
        { id: 'BSQU', val: 'Basque Country' },
        { id: 'BLRS', val: 'Belearas' },
        { id: 'CNRY', val: 'Canary Islands' },
        { id: 'CNTB', val: 'Cantabria' },
        { id: 'MNCH', val: 'Castilla-la Mancha' },
        { id: 'CLYL', val: 'Castille Y Leon' },
        { id: 'CTLN', val: 'Catalonia' },
        { id: 'CUTA', val: 'Ceuta' },
        { id: 'EXTR', val: 'Extremadura' },
        { id: 'GLCA', val: 'Galicia' },
        { id: 'RJLA', val: 'La Rioja' },
        { id: 'MDRD', val: 'Madrid' },
        { id: 'MLLA', val: 'Melilla' },
        { id: 'MRCA', val: 'Murcia' },
        { id: 'NVRA', val: 'Navarra' },
        { id: 'VLNC', val: 'Valencian Community' }
      ]
    },
    'Sri Lanka': { zone: '', states: [] },
    'St. Helena': { zone: '', states: [] },
    'St. Pierre And Miquelon': { zone: '', states: [] },
    'St. Vincent And The Grenadines': { zone: '', states: [] },
    Sudan: { zone: '', states: [] },
    Suriname: { zone: '', states: [] },
    'Svalbard And Jan Mayen Islands': { zone: '', states: [] },
    Sweden: { zone: 'EU', states: [] },
    Switzerland: { zone: 'EU', states: [] },
    Taiwan: { zone: '', states: [] },
    Tajikistan: { zone: '', states: [] },
    'Tanzania, United Republic Of': { zone: '', states: [] },
    Thailand: { zone: '', states: [] },
    'Timor-Leste': { zone: '', states: [] },
    Togo: { zone: '', states: [] },
    Tokelau: { zone: '', states: [] },
    Tonga: { zone: '', states: [] },
    'Trinidad And Tobago': { zone: '', states: [] },
    Tunisia: { zone: '', states: [] },
    Turkey: { zone: '', states: [] },
    Turkmenistan: { zone: '', states: [] },
    'Turks And Caicos Islands': { zone: '', states: [] },
    Tuvalu: { zone: '', states: [] },
    'U.S. Minor Outlying Islands': { zone: '', states: [] },
    Uganda: { zone: '', states: [] },
    'Ukraine (excluding Crimea, Donetsk, and Luhansk regions)': { zone: '', states: [] },
    'United Arab Emirates': { zone: '', states: [] },
    'United Kingdom': { zone: 'EU', states: [] },
    'United States': {
      zone: '',
      states: [
        { id: 'AL', val: 'Alabama' },
        { id: 'AK', val: 'Alaska' },
        { id: 'AZ', val: 'Arizona' },
        { id: 'AR', val: 'Arkansas' },
        { id: 'CA', val: 'California' },
        { id: 'CO', val: 'Colorado' },
        { id: 'CT', val: 'Connecticut' },
        { id: 'DE', val: 'Delaware' },
        { id: 'FL', val: 'Florida' },
        { id: 'GA', val: 'Georgia' },
        { id: 'HI', val: 'Hawaii' },
        { id: 'ID', val: 'Idaho' },
        { id: 'IL', val: 'Illinois' },
        { id: 'IN', val: 'Indiana' },
        { id: 'IA', val: 'Iowa' },
        { id: 'KS', val: 'Kansas' },
        { id: 'KY', val: 'Kentucky' },
        { id: 'LA', val: 'Louisiana' },
        { id: 'ME', val: 'Maine' },
        { id: 'MD', val: 'Maryland' },
        { id: 'MA', val: 'Massachusetts' },
        { id: 'MI', val: 'Michigan' },
        { id: 'MN', val: 'Minnesota' },
        { id: 'MS', val: 'Mississippi' },
        { id: 'MO', val: 'Missouri' },
        { id: 'MT', val: 'Montana' },
        { id: 'NE', val: 'Nebraska' },
        { id: 'NV', val: 'Nevada' },
        { id: 'NH', val: 'New Hampshire' },
        { id: 'NJ', val: 'New Jersey' },
        { id: 'NM', val: 'New Mexico' },
        { id: 'NY', val: 'New York' },
        { id: 'NC', val: 'North Carolina' },
        { id: 'ND', val: 'North Dakota' },
        { id: 'OH', val: 'Ohio' },
        { id: 'OK', val: 'Oklahoma' },
        { id: 'OR', val: 'Oregon' },
        { id: 'PA', val: 'Pennsylvania' },
        { id: 'RI', val: 'Rhode Island' },
        { id: 'SC', val: 'South Carolina' },
        { id: 'SD', val: 'South Dakota' },
        { id: 'TN', val: 'Tennessee' },
        { id: 'TX', val: 'Texas' },
        { id: 'UT', val: 'Utah' },
        { id: 'VT', val: 'Vermont' },
        { id: 'VA', val: 'Virginia' },
        { id: 'WA', val: 'Washington' },
        { id: 'DC', val: 'Washington DC' },
        { id: 'WV', val: 'West Virginia' },
        { id: 'WI', val: 'Wisconsin' },
        { id: 'WY', val: 'Wyoming' }
      ]
    },
    Uruguay: { zone: '', states: [] },
    Uzbekistan: { zone: '', states: [] },
    Vanuatu: { zone: '', states: [] },
    Venezuela: { zone: '', states: [] },
    Vietnam: { zone: '', states: [] },
    'Virgin Islands (British)': { zone: '', states: [] },
    'Virgin Islands, U.S.': { zone: '', states: [] },
    'Wallis And Futuna Islands': { zone: '', states: [] },
    'Western Sahara': { zone: '', states: [] },
    Yemen: { zone: '', states: [] },
    Zambia: { zone: '', states: [] },
    Zimbabwe: { zone: '', states: [] },
    'Åland Islands': { zone: '', states: [] }
};
