import { Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn } from '@angular/forms';

import { map, Subscription } from 'rxjs';

import { WgDropdownItem } from '@watchguard/wg-dropdown';
import { LoaderService } from '@watchguard/wg-loader';

import { CommonHelperService } from '../../shared/common-helper.service';
import { CustomValidatorService } from '../../shared/custom-validator.service';
import {
  GetAccountInfoByContactIdResponse,
  IndustryList,
  InviteFromSalesforce,
  UserExistsApiResponse,
  ValidatePasswordResponse } from '../../shared/model';
import { ADD_NEW_ACC_STRINGS, APP_TITLE_STRINGS, ERROR_STRINGS, INVITE_FROM_SF_COMP_STRINGS } from '../../shared/ui-strings';
import { countriesList } from '../../shared/countriesList';
import { InviteFromSalesforceHelperService } from '../invite-from-sf-helper.service';


@Component({
  selector: 'wgc-invite-user-in-new-account',
  templateUrl: './invite-user-in-new-account.component.html'
})
export class InviteUserInNewAccountComponent implements OnInit, OnDestroy {

  @Input() accountInfo: GetAccountInfoByContactIdResponse;
  @Input() contactId: string;

  isShowSelectRegion: boolean = false;
  isShowOptInEmail: boolean = false;
  isPasswordValid: boolean = false;
  isUsernameValid: boolean = false;
  form: UntypedFormGroup;
  isDuplicateUsername: UserExistsApiResponse;
  isPasswordCompromised: ValidatePasswordResponse;
  payload: InviteFromSalesforce;

  addAccCompStrs = ADD_NEW_ACC_STRINGS;
  errorStrs = ERROR_STRINGS;
  titleStrs = APP_TITLE_STRINGS;
  compStrs = INVITE_FROM_SF_COMP_STRINGS;

  industryList: WgDropdownItem[] = [];
  countryList: WgDropdownItem[] = [];
  stateList: WgDropdownItem[] = [];

  industrySelected!: WgDropdownItem;
  countrySelected!: WgDropdownItem;
  stateSelected: WgDropdownItem;

  checkUserNameExistsSub: Subscription;
  checkEmailExistsSub: Subscription;
  validatePasswordSub: Subscription;

  userNameExistsValFn: ValidatorFn;
  stateRequiredFn: ValidatorFn = this.customValidator.requiredFn('state');
  matchPasswordValFn: ValidatorFn;
  passwordCompromisedValFn: ValidatorFn;

  constructor(
    private fb: UntypedFormBuilder,
    private customValidator: CustomValidatorService,
    private commonHelperService: CommonHelperService,
    private loaderService: LoaderService,
    private el: ElementRef,
    private inviteFromSfHelperService: InviteFromSalesforceHelperService) {
  }

  ngOnInit(): void {
    this.commonHelperService.setTitle(this.titleStrs.invite_from_sf);
    this.initForm();
    this.setDropDownItems();
    this.initValidators();
  }

  initValidators(): void {
    this.passwordCompromisedValFn = this.inviteFromSfHelperService.passwordCompromisedValFn;
    this.matchPasswordValFn = this.inviteFromSfHelperService.matchPasswordValFn;
    this.userNameExistsValFn = this.inviteFromSfHelperService.userNameExistsValFn;
  }

  initForm(): void {
    this.form = this.fb.group({
      firstName: [
        '',
        [
          this.customValidator.requiredFn('firstName'),
          this.customValidator.regexFn('firstName'),
          this.customValidator.lengthValFn('firstName', 40)
        ]
      ],
      lastName: [
        '',
        [
          this.customValidator.requiredFn('lastName'),
          this.customValidator.regexFn('lastName'),
          this.customValidator.lengthValFn('lastName', 40)
        ]
      ],
      userName: [
        '',
        [
          this.customValidator.requiredFn('userName'),
          this.customValidator.regexFn('userName'),
          this.customValidator.lengthValFn('userName', 65, 5)
        ]
      ],
      phone: [
        '',
        [
          this.customValidator.requiredFn('phone'),
          this.customValidator.regexFn('phone'),
          this.customValidator.lengthValFn('phone', 40, 6)
        ]
      ],
      companyName: [
        '',
        [
          this.customValidator.requiredFn('companyName'),
          this.customValidator.regexFn('companyName')
        ]
      ],
      industry: [null, this.customValidator.requiredFn('industry')],
      street: [
        '',
        [
          this.customValidator.requiredFn('street'),
          this.customValidator.regexFn('street'),
          this.customValidator.lengthValFn('streetAddress', 150)
        ]
      ],
      postalCode: [
        '',
        [
          this.customValidator.requiredFn('postalCode'),
          this.customValidator.regexFn('postalCode'),
          this.customValidator.lengthValFn('postalCode', 20)
        ]
        ],
      city: [
        '',
        [
          this.customValidator.requiredFn('city'),
          this.customValidator.regexFn('city'),
          this.customValidator.lengthValFn('city', 40)
        ]
      ],
      state: [null],
      country: [null, [this.customValidator.requiredFn('country')]],
      optedInForEmail: [false],
      password: [
        '',
        [
          this.customValidator.requiredFn('password'),
          this.customValidator.regexFn('password')
        ]
      ],
      confirm_password: [
        '',
        [
          this.customValidator.requiredFn('confirm_password')
        ]
      ]
    });
  }

  setDropDownItems(): void {
    Object.values(IndustryList)
    .forEach((industry: IndustryList) => this.industryList.push({ id: industry, val: industry }));

    Object.keys(countriesList)
    .forEach((country: string) => this.countryList.push({ id: country, val: country }));
  }

  updateStateList(): void {
    const countryInfo = countriesList[this.countrySelected.val];
    const stateField: AbstractControl = this.form.controls.state;

    this.isShowOptInEmail = countryInfo.zone === 'EU';

    this.stateList = countryInfo.states;

    this.stateSelected = null;
    stateField.setValue(null);

    if (this.stateList.length) {
      stateField.markAsUntouched();
      stateField.addValidators([this.stateRequiredFn]);
    } else {
      stateField.removeValidators([this.stateRequiredFn]);
    }

    stateField.updateValueAndValidity();
  }

  dropdownSelected(item: WgDropdownItem, field: string): void {
    if (field === 'industry') {
      this.industrySelected = item;
    } else if (field === 'country') {
      this.countrySelected = item;
      this.updateStateList();
    } else if (field === 'state') {
      if (!this.stateSelected || item.id !== this.stateSelected.id) {
        this.stateSelected = item;
      }
    }
  }

  updateUsernameFieldValidity(): void {
    const userNameField: AbstractControl = this.form.controls.userName;
    userNameField.removeValidators([this.userNameExistsValFn]);
    userNameField.updateValueAndValidity();
  }

  generatePayloadForInviteUserInNewAccount(): InviteFromSalesforce {
    const formData: InviteFromSalesforce = {
      ...this.form.value,
      industry: this.industrySelected.val,
      country: this.countrySelected.val,
      state: this.stateSelected?.id || '',
      nativeSalesforceContactId: this.contactId,
      accountId: this.accountInfo.account_details.contact.accountId__c,
      accountExists: this.accountInfo.account_exists,
      email: this.accountInfo.account_details.contact.email
    };

    return formData;
  }

  validateSamePassword(): void {
    this.inviteFromSfHelperService.validateSamePassword(this.form);
  }

  handleValidatePasswordAndUsernameResponse(isPasswordCompromised: ValidatePasswordResponse, isDuplicateUsername: UserExistsApiResponse): void {
    this.isPasswordValid = this.inviteFromSfHelperService.handlePasswordValidation(isPasswordCompromised, this.form, this.el);
    this.isUsernameValid = this.inviteFromSfHelperService.handleUsernameValidation(isDuplicateUsername, this.form, this.el);

    if (this.isPasswordValid && this.isUsernameValid) {
      this.payload = this.generatePayloadForInviteUserInNewAccount();
      this.isShowSelectRegion = true;
    }

    this.loaderService.hideLoading();
  }

  validatePasswordAndUsername(): void {
    this.inviteFromSfHelperService.validatePasswordAndUsername(this.form).pipe(map(([isPasswordCompromised, isDuplicateUsername]) => {
      return (this.isPasswordCompromised = isPasswordCompromised, this.isDuplicateUsername = isDuplicateUsername);
      })).subscribe({
        next: ()  => this.handleValidatePasswordAndUsernameResponse(this.isPasswordCompromised, this.isDuplicateUsername),
        error: () => this.commonHelperService.handleApiError()
      });
  }

  submit(): void {
    if (this.form.valid) {
      this.validatePasswordAndUsername();
    } else {
      this.form.markAllAsTouched();
      this.commonHelperService.setFocusOnInvalidFormField(this.form, this.el);
    }
  }

  ngOnDestroy(): void {
    this.checkUserNameExistsSub?.unsubscribe();
    this.validatePasswordSub?.unsubscribe();
  }
}

