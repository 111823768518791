export const ADD_NEW_ACC_STRINGS = {
    create_acc_heading: 'create_acc_heading',
    first_name_lbl: 'create_acc_first_name_lbl',
    last_name_lbl: 'create_acc_last_name_lbl',
    username_lbl: 'create_acc_username_lbl',
    email_lbl: 'create_acc_email_lbl',
    phone_lbl: 'create_acc_phone_lbl',
    company_name_lbl: 'create_acc_company_name_lbl',
    industry_lbl: 'create_acc_industry_lbl',
    street_address_lbl: 'create_acc_street_address_lbl',
    postal_code_lbl: 'create_acc_postal_code_lbl',
    city_lbl: 'create_acc_city_lbl',
    state_province_region_lbl: 'create_acc_state_province_region_lbl',
    country_lbl: 'create_acc_country_lbl',
    receive_marketing_email_text: 'create_acc_receive_marketing_email_text',
    create_acc_btn_lbl: 'create_acc_btn_lbl',
    account_type_lbl: 'account_type_lbl',

    create_acc_check_email_msg: 'create_acc_check_email_msg',
    create_acc_contact_msg: 'create_acc_contact_msg',

    select_region_heading: 'select_region_heading',
    select_region_sub_heading: 'select_region_sub_heading',
    select_region_sub_heading_2: 'select_region_sub_heading_2',
    select_region_learn_more_text: 'select_region_learn_more_text',
    select_region_dropdown_lbl: 'select_region_dropdown_lbl',
    select_region_dropdown_placeholder_lbl: 'select_region_dropdown_placeholder_lbl',
    save_region_btn_lbl: 'select_region_save_region_btn_lbl',
    confirm_region_heading: 'select_region_confirm_region_heading',
    confirm_region_heading_2: 'select_region_confirm_region_heading_2',
    confirm_select_region_lbl: 'select_region_confirm_lbl',
    set_region_btn_lbl: 'select_region_set_region_btn_lbl',

    congratulations_heading: 'congratulations_heading',
    acc_created_msg: 'acc_created_msg',
    return_to_login_page_btn_lbl: 'return_to_login_page_btn_lbl',
    enter_new_password_heading: 'set_password_heading',
    password_lbl: 'set_password_password_lbl',
    confirm_password_lbl: 'set_password_confirm_password_lbl',
    set_password_btn_lbl: 'set_password_save_password_btn_lbl',

    duplicate_email_dialog_proceed_btn_lbl: 'duplicate_email_dialog_btn_proceed_lbl',
    duplicate_email_dialog_btn_no_lbl: 'duplicate_email_dialog_btn_no_lbl',
    duplicate_email_dialog_title: 'duplicate_email_dialog_title'

};

export const RESETS_PASSWORD_STRINGS = {
    page_heading: 'reset_password_heading',
    enter_user_name_text: 'reset_enter_user_name_text',
    username_lbl: 'reset_username_lbl',
    submit_req_btn: 'reset_submit_req_btn',
    email_sent_msg: 'reset_email_sent_msg',
    email_sent_msg_2: 'reset_email_sent_msg_2',
    reset_password_privacy_policy_text: 'reset_password_privacy_policy_text',
    reset_password_successful: 'reset_password_successful',
    return_to_login_page_btn_lbl: 'return_to_login_page_btn_lbl'
};

export const RETRIEVE_USER_NAME_STRINGS = {
    page_heading: 'retrieve_user_name_heading',
    enter_email_text: 'retrieve_enter_email_text',
    email_lbl: 'retrieve_email_lbl',
    submit_req_btn: 'retrieve_submit_req_btn',
    email_sent_msg: 'retrieve_email_sent_msg',
    email_sent_msg_2: 'retrieve_user_name_email_sent_msg_2'
};

export const ERROR_STRINGS = {
    first_name_req: 'create_acc_err_first_name_req',
    first_name_length: 'create_acc_err_first_name_length',
    last_name_req: 'create_acc_err_last_name_req',
    last_name_length: 'create_acc_err_last_name_length',
    user_name_req: 'create_acc_err_user_name_req',
    user_name_invalid: 'create_acc_err_user_name_invalid',
    user_name_unique: 'create_acc_err_user_name_unique',
    user_name_length: 'create_acc_err_user_name_length',
    email_req: 'create_acc_err_email_req',
    email_invalid: 'create_acc_err_email_invalid',
    email_duplicate: 'duplicate_email_dialog_content_msg',
    email_length: 'create_acc_err_email_length',
    phone_req: 'create_acc_err_phone_req',
    phone_length: 'create_acc_err_phone_length',
    phone_invalid: 'create_acc_err_phone_invalid',
    company_req: 'create_acc_err_company_req',
    company_invalid: 'create_acc_err_company_name_invalid',
    industry_req: 'create_acc_err_industry_req',
    street_address_req: 'create_acc_err_street_address_req',
    street_address_length: 'create_acc_err_street_length',
    street_address_invalid: 'create_acc_err_street_address_invalid',
    postal_code_req: 'create_acc_err_postal_code_req',
    postal_code_length: 'create_acc_err_postal_code_length',
    postal_code_invalid: 'create_acc_err_postal_code_invalid',
    city_req: 'create_acc_err_city_req',
    city_length: 'create_acc_err_city_length',
    state_province_region_req: 'create_acc_err_state_province_region_req',
    country_req: 'create_acc_err_country_req',
    user_exists_email_info_msg: 'create_acc_user_exists_email_info_msg',
    special_chars_not_allowed: 'create_acc_err_special_chars_not_allowed',

    reset_password_username_req: 'reset_password_err_username_req',
    reset_password_email_req: 'retrieve_username_err_email_req',
    reset_password_failure: 'reset_password_failure',

    password_req: 'set_password_err_password_req',
    password_invalid: 'set_password_err_password_invalid',
    confirm_password_req: 'set_password_err_confirm_password_req',
    confirm_password_mismatch: 'set_password_err_confirm_password_mismatch',
    set_password_failure: 'set_password_failure',
    compromised_password: 'set_password_err_password_compromised',

    select_region_req: 'select_region_err_select_region_req',
    confirm_select_region_req: 'select_region_err_confirm_select_region_req',
    confirm_region_mismatch: 'select_region_err_confirm_region_mismatch',

    api_request_err_message: 'api_request_err_message',
    api_bad_request_err_msg: 'api_bad_request_err_msg'
};

export const ERROR_COMP_STRINGS = {
    page_heading: 'error_heading',
    contact_customer_care: 'error_sub_heading',
    redirect_to_start_reg: 'error_sub_heading2',
    reset_password_error_heading: 'reset_password_error_heading',
    reset_password_error_subheading: 'reset_password_error_subheading',
    redirect_to_reset_password: 'reset_password_error_subheading2',
    set_password_error_heading: 'set_password_error_heading',
    return_to_wg_cloud_msg: 'return_to_wg_cloud_msg',
    return_to_wg_cloud: 'return_to_wg_cloud',
    duplicate_email_error_heading: 'duplicate_email_error_heading',
    duplicate_email_error_subheading: 'duplicate_email_error_subheading',
    could_not_add_operator: 'could_not_add_operator_heading',
    region_not_set: 'region_not_set_msg',
    contact_customer_support_for_region: 'customer_support_for_region'
};

export const INVITE_FROM_SF_COMP_STRINGS = {
    invite_from_sf_heading: 'invite_from_sf_heading',
    invite_user_btn_lbl : 'invite_user_btn_lbl'
};

export const APP_TITLE_STRINGS = {
    wg_technologies: 'WatchGuard Technologies',
    create_account: 'create_account_title',
    set_password: 'set_password_title',
    reset_password: 'reset_password_title',
    select_region: 'select_region_title',
    forgot_password: 'forgot_password_title',
    forgot_user_name: 'forgot_user_name_title',
    invite_from_sf: 'invite_from_sf_title'
};

export const EMAIL_CHANGE_STRINGS = {
    email_title:'email_title',
    email_message: 'email_message',
    confirm_email_success: 'confirm_email_success',
    cancel_email_title:'cancel_email_heading',
    cancel_email_text: 'cancel_email_subheading',
    cancel_email_message: 'cancel_email_message',
    no_pending_email_found: 'no_pending_email_found',
    email_change_btn_lbl: 'email_change_btn_lbl',
    email_cancel_btn_lbl: 'email_cancel_btn_lbl'  ,
    previous_email_address_lbl: 'previous_email_address_lbl',
    new_email_address_lbl: 'new_email_address_lbl'
}
