import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

import { TranslateService } from '@ngx-translate/core';

import { ERROR_STRINGS } from '../shared/ui-strings';


@Injectable({
    providedIn: 'root'
})
export class CustomValidatorService {

    errorStrs = ERROR_STRINGS;
    constructor(private translate: TranslateService) {}

    getRegex(field: string): RegExp {
        const regExpressions: { [key: string]: RegExp } = {
            userName: /^[\w\-\+\.]+$/i,
            email: /^[\w+\d*!#$%&'*+/=.?^`\{|}~-]+@([a-zA-Z\d-]+\.)+[a-zA-Z\d]+$/i,
            phone: /^\d+$/i,
            firstName: /^[a-z0-9\s]+$/i,
            lastName: /^[a-z0-9\s]+$/i,
            city: /^[a-z]+(\s+[a-z]+)*$/i,
            postalCode: /^[a-z0-9\-]+$/i,
            street: /^[a-z0-9]+[a-z0-9\s\.\-\#\&]*$/i,
            companyName: /^[a-z0-9]+[a-z0-9_\-\s\.]*[a-z0-9]+$/i
        };

        return regExpressions[field];
    }

    cleanValue(value: any): void {
        return typeof value === 'object' ? value : value.trim();
    }

    reqErrorMsgs = (field: string): string => {
        const errorMsgMap: { [key: string]: string} = {
            firstName: this.errorStrs.first_name_req,
            lastName: this.errorStrs.last_name_req,
            userName: this.errorStrs.user_name_req,
            email: this.errorStrs.email_req,
            phone: this.errorStrs.phone_req,
            companyName: this.errorStrs.company_req,
            industry: this.errorStrs.industry_req,
            street: this.errorStrs.street_address_req,
            postalCode: this.errorStrs.postal_code_req,
            city: this.errorStrs.city_req,
            state: this.errorStrs.state_province_region_req,
            country: this.errorStrs.country_req,
            password: this.errorStrs.password_req,
            confirm_password: this.errorStrs.confirm_password_req,
            region: this.errorStrs.select_region_req,
            confirm_region: this.errorStrs.confirm_select_region_req
        };
        // return empty if not found, since public
        return errorMsgMap[field];
    };

    invalidErrorMsgs = (field: string): string => {
        const errorMsgMap: { [key: string]: string} = {
            userName: this.errorStrs.user_name_invalid,
            userNameUnique: this.errorStrs.user_name_unique,
            email: this.errorStrs.email_invalid,
            phone: this.errorStrs.phone_invalid,
            password: this.errorStrs.password_invalid,
            password_mismatch: this.errorStrs.confirm_password_mismatch,
            password_compromised: this.errorStrs.compromised_password,
            region_mismatch: this.errorStrs.confirm_region_mismatch,
            firstName: this.errorStrs.special_chars_not_allowed,
            lastName: this.errorStrs.special_chars_not_allowed,
            city: this.errorStrs.special_chars_not_allowed,
            postalCode: this.errorStrs.postal_code_invalid,
            street: this.errorStrs.street_address_invalid,
            companyName: this.errorStrs.company_invalid
        };
        return errorMsgMap[field];
    };

    lengthErrorMsgs = (field: string): string => {
        const errorMsgMap: { [key: string]: string } = {
            userName: this.errorStrs.user_name_length,
            email: this.errorStrs.email_length,
            phone: this.errorStrs.phone_length,
            firstName: this.errorStrs.first_name_length,
            lastName: this.errorStrs.last_name_length,
            city: this.errorStrs.city_length,
            postalCode: this.errorStrs.postal_code_length,
            streetAddress: this.errorStrs.street_address_length
        };
        return errorMsgMap[field];
    };

    requiredFn(field: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control) {
                const value = control.value ? this.cleanValue(control.value) : '';
                if (!value) {
                    const requiredErrorMsg = this.reqErrorMsgs(field);
                    return {
                        required: {
                            message: this.translate.instant(requiredErrorMsg)
                        }
                    };
                }
                return null;
            }
            return null;
        };
    }

    validatePassword(password: string): boolean {
        const lowercaseLetter = /^(?=.*[a-z])/;
        const uppercaseLetter = /^(?=.*[A-Z])/;
        const digit = /^(?=.*\d)/;
        const specialCharacter = /^(?=.*[!"#$%@&'()*+,\-./:;=?\[\]^_{}~`])/;
        const onlySpecifiedCharacter = /^[\p{L}\p{M}\d!"#$%@&'()*+,\-./:;=?\[\]^_{}~`]{12,}$/u;

        const isValidPassword = lowercaseLetter.test(password) 
            && uppercaseLetter.test(password) 
            && digit.test(password)
            && specialCharacter.test(password) 
            && onlySpecifiedCharacter.test(password);
        return isValidPassword;
    }

    regexFn(field: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (control) {
                if (control.value) {
                    const value = control.value ? this.cleanValue(control.value) : '';
                    const invalidErrorMsg = this.invalidErrorMsgs(field);
                    if (value && (field === 'password')) {
                        const result = this.validatePassword(value);
                        if (result) {
                            return null;
                        }
                    }
                    const regex: RegExp = this.getRegex(field);
                    if (!regex || (value && !regex.test(value))){
                        return {
                            error: {
                                message: this.translate.instant(invalidErrorMsg)
                            }
                        };
                    }
                    return null;
                }
            }
            return null;
        };
    }

    matchFieldFn(field: string, fieldToMatch: string, type: string = 'text'): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (control) {
                const value = control.value ? this.cleanValue(control.value) : '';

                let valueToMatch = control.parent?.value[fieldToMatch];
                valueToMatch = type === 'dropDown' ? valueToMatch?.val : valueToMatch;

                const invalidErrorMsg = this.invalidErrorMsgs(field);

                if (value && valueToMatch && (value !== valueToMatch)) {
                    return {
                        error: {
                            message: this.translate.instant(invalidErrorMsg)
                        }
                    };
                }
                return null;
            }
            return null;
        };
    }

    commonValFn(field: string): ValidatorFn {

        const invalidErrorMsg = this.invalidErrorMsgs(field);

        return (control: AbstractControl): ValidationErrors | null => {
            return {
                required: {
                    message: this.translate.instant(invalidErrorMsg)
                }
            };
        };
    }

    lengthValFn(field: string,  maxLength: number, minLength?: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {

            if (control) {
                const fieldValueLength = control.value.trim().length;

                if ((minLength && fieldValueLength < minLength) || (fieldValueLength > maxLength)) {
                    const lengthErrorMsg = this.lengthErrorMsgs(field);
                     return {
                         error: {
                             message: this.translate.instant(lengthErrorMsg)
                        }
                    };
                }
            }
            return null;
        };
    }

}
