import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpParams
} from '@angular/common/http';
import {ActivatedRoute, Params } from '@angular/router';

import { Observable } from 'rxjs';

import { PayloadHashingService } from './payload-hashing.service';
import { ReqParam } from './shared/model';
import { StorageHelperService } from './shared/storage-helper.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private payloadHashingService: PayloadHashingService,
    private route: ActivatedRoute,
    private storageHelperService: StorageHelperService) { }

  base64Encode(key: string): string {
    const encoder = new TextEncoder();
    const bytes = encoder.encode(key);
    const byteString = String.fromCharCode.apply(null, bytes);
    const base64Encoded = btoa(byteString);
    return base64Encoded;
  }
  
  encodeQueryParams(queryParams: HttpParams): ReqParam {
    const encodedParams = {};

    queryParams.keys().forEach((key) => {
      encodedParams[key] = this.base64Encode(queryParams.get(key));
    });

    return encodedParams;
  }

  setAuthorizationHeader(req: HttpRequest<any>): HttpRequest<any> {
    const reqParams = req.params;
    let token;   

    if (reqParams.keys().length !== 0) {
      const encodedParams: ReqParam = this.encodeQueryParams(reqParams);
      const updatedReqParams = Object.keys(encodedParams).map(key => `${key}=${encodedParams[key]}`).join('&')

      req = req.clone({
        params: new HttpParams({ fromObject: encodedParams })
      });

      const sortedRequestParams = updatedReqParams.split('').sort().join('');
      token = this.payloadHashingService.hashPayload(sortedRequestParams);
    } else if (req.body) {
      token = this.payloadHashingService.hashPayload(JSON.stringify(req.body));
    } else {
      const sessionToken: string = this.storageHelperService.getSession("token");
      const sessionFederationId: string = this.storageHelperService.getSession("federationId");
      const joinTokenFederationId: string = sessionToken+'&'+sessionFederationId;      
      token = this.getToken(joinTokenFederationId);
      this.storageHelperService.removeSession("token");
      this.storageHelperService.removeSession("federationId");
    }

    const bearerToken = 'Bearer ' + token;
    req = req.clone({
      headers: req.headers.set('Authorization', bearerToken)
    });
    return req;
  }

  getToken(updatedReqParams: string): string {       
      const sortedRequestParams: string = updatedReqParams.split('').sort().join('');
      return this.payloadHashingService.hashPayload(sortedRequestParams);      
  }
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const localDev = window.location.href.indexOf('watchguard.com') === -1;
    const serverlessApi = this.isServerlessApi(req.url);

    // Uncomment below to replace all override (service discovery) URLs with the real ones.
    // This way, you can use a real session if logged into dev
    if (localDev) {
      req = this._forLocalDev(req, serverlessApi);
    }

    if (/(\/cloud\/|\/aaas\/)/.test(req.url) || serverlessApi) {
      req = this.setAuthorizationHeader(req);

      // Get aound the CORS policy issue when running locally.
      // This must be after setHeaders() above.
      if (localDev && serverlessApi) {
        req = this.getAroundCorsPolicy(req);
      }
    } else {
      // append date when retrieving .json files to avaoid caching
      if (req.url.endsWith('.json')) {
        req = req.clone({
          url: req.url + '?' + Date.now()
        });
      }
    }
    return next.handle(req);
  }

  private _forLocalDev(req: HttpRequest<any>, serverlessApi = false): HttpRequest<any> {
    // no change to the url used for serverless API. Otherwise, proceed as normal.
    if (!serverlessApi) {
      const url = req.url.split('/');
      if (url.length > 4 && url[3] !== 'cloud') {
        req = req.clone({
          url: req.url.replace(/\.com\/.*?\//, '.com/')
        });
      }
    }
    return req;
  }


  // match the keyword to dermine if the request is for a serverless API.
  private isServerlessApi(url: string): boolean {
    if (!url) {
      return false;
    }

    // API's for Platform, https://guardapi.dev.usa.cloud.watchguard.com/<application>-<servicename>
    if (/guardapi/.test(url)) {
      return true;
    }

    return false;
  }

  // To get around the CORS Policy issue when running locally.
  private getAroundCorsPolicy(req: HttpRequest<any>): HttpRequest<any> {

    // This is for a custom domain, https://guardapi.dev.usa.cloud.watchguard.com
    //
    // This is to redirect http://localhost:4200/platform-groupmgr-svc/** to
    // https://guardapi.dev.usa.cloud.watchguard.com/platform-groupmgr-svc/**
    //
    // Note: The corresponding "base path mapping" entry in proxy.config.json is the /platform-groupmgr-svc/**,
    // "/platform-groupmgr-svc/**": {
    //   "target": "https://guardapi.dev.usa.cloud.watchguard.com",
    //   "secure": false,
    //   "changeOrigin": true
    // }
    //

    if (/guardapi.dev/.test(req.url)) {
      return req.clone({
        url: req.url.replace('https://guardapi.dev.usa.cloud.watchguard.com', '')
      });
    }

    if (/guardapi.wgid.dev/.test(req.url)) {
      return req.clone({
        url: req.url.replace('https://guardapi.wgid.dev.usa.cloud.watchguard.com', '')
      });
    }

    return req;
  }
}
