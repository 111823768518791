import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

import { Observable, Subscription } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { TranslateService } from '@ngx-translate/core';

import { LoaderService } from '@watchguard/wg-loader';

import { ApiResponse, CancelPendingEmail, GetPendingEmailResponse, TranslationParam, UrlData } from '../shared/model';
import { ValidateEmailTokenResponse, ErrorContent, ConfirmPendingEmail } from '../shared/model';
import { CommonHelperService } from '../shared/common-helper.service';
import { StorageHelperService } from '../shared/storage-helper.service';
import { AccountManagerService } from '../account-manager.service';
import { ADD_NEW_ACC_STRINGS, EMAIL_CHANGE_STRINGS,ERROR_COMP_STRINGS } from '../shared/ui-strings';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html'
})
export class EmailComponent {

  token: string = '';
  b2cToken: string = '';
  claimPayload: string = '';
  federation_id: string = '';
  account_id: string = '';
  region_id: number = -1;
  username: string = '';
  oldEmail: string = '';
  newEmail: string = '';
  signInUrl: string = '';
  wgCloudLink: string = this.commonHelperService.getUrlByEnv('signInToCloud');

  isShowConfirmation: boolean = false;
  isShowEmail: boolean = false;
  isShowForConfirmEmaillbl: boolean = true;
  isShowCancelConfirmation: boolean = false;

  confirmEmailPayload: ConfirmPendingEmail;

  fetchQueryParamsSub: Subscription;
  validTokenSub: Subscription;

  wgCloudLinkTransParams: TranslationParam = { wgCloudLink: this.wgCloudLink }

  addAccCompStrs = ADD_NEW_ACC_STRINGS;
  emailChangeString = EMAIL_CHANGE_STRINGS;
  errorCompStrs = ERROR_COMP_STRINGS;

  setNoPendingEmailContent: ErrorContent = {
    errorHeader: this.emailChangeString.no_pending_email_found,
    errorMessage: this.errorCompStrs.contact_customer_care,
    redirectMessage: this.translateService.instant(this.errorCompStrs.return_to_wg_cloud_msg, this.wgCloudLinkTransParams)
  };  

  setError: ErrorContent = {
    errorHeader: this.errorCompStrs.page_heading,
    errorMessage: this.errorCompStrs.contact_customer_care,
    redirectMessage: this.errorCompStrs.redirect_to_start_reg
  };  

  constructor(
    private route: ActivatedRoute,
    private commonHelperService: CommonHelperService,
    private accountManagerService: AccountManagerService,
    private loaderService: LoaderService,
    private authService: MsalService,
    private storageService: StorageHelperService,
    private translateService: TranslateService
    ) { }

  ngOnInit(): void {
    this.loaderService.showLoading();
    this.getAccessToken();
  }

  setUserFlow(): void {
    const urlData: UrlData = { params: {} };
    this.fetchQueryParamsSub = this.route.queryParams.subscribe((queryParams: Params) => {
      urlData.params = queryParams;
      this.token = urlData.params.token;
      this.storageService.setSession("token",this.token);      
    });
  }

  validateConfirmEmailToken(): void {
    this.accountManagerService.validateEmailToken(this.token, this.federation_id).subscribe({
      next: (data: ValidateEmailTokenResponse) => this.handleValidateEmailTokenResponse(data),
      error: () => this.showApiErrors()
    });
  }

  handleValidateEmailTokenResponse(data: ValidateEmailTokenResponse): void {
    if (data.status === 'success' && data.isTokenValid) {
      this.oldEmail = data.oldEmailId;
      this.newEmail = data.pendingEmailId;
      this.isShowEmail = true;
    } else {      
      this.commonHelperService.showApiError(this.setError);
    }
    this.loaderService.hideLoading();
  }

  confirmEmailRequest(): void {
    this.loaderService.showLoading();
    this.accountManagerService.confirmPendingEmail(this.generatePayload()).subscribe({
      next: (data: ApiResponse) => this.handelConfirmEmailChangeRequset(data),
      error: () => this.showApiErrors()
    });
  }

  cancelEmailRequest(): void {
    this.loaderService.showLoading();
    this.accountManagerService.cancelPendingEmail(this.generateCancelPaylaod()).subscribe({
      next: (data: ApiResponse) => this.handleCancelEmailChangeResponse(data),
      error: () => this.showApiErrors()
    });
  }

  handelConfirmEmailChangeRequset(data: ApiResponse): void {
    if(data.status == 'success'){
      this.isShowConfirmation = true;
      setTimeout(() => {
        this.returnToCloudLogin();
      }, 10000);
    }
    this.handleEmailResponse(data);    
  }

  handleCancelEmailChangeResponse(data: ApiResponse): void {
    if(data.status == 'success'){
      this.isShowCancelConfirmation = true;
    }
    this.handleEmailResponse(data);
  }

  handleEmailResponse(data: ApiResponse): void{
    if (data.status == 'success') {
      this.signInUrl = this.commonHelperService.getUrlByEnv('signInToCloud');
      this.isShowEmail = false;      
    } else {      
      this.commonHelperService.showApiError(this.setError);
    }
    this.loaderService.hideLoading();
  }

  generatePayload(): ConfirmPendingEmail {
    const payload: ConfirmPendingEmail = {
      token: this.token,
      email: this.newEmail,
      federation_id: this.federation_id,
      account_id: this.account_id,
      region_id: this.region_id,
      username: this.username
    };
    return payload;
  }

  getAccessToken(): void {
    const accessTokenRequest = {
      scopes: [this.commonHelperService.getConfigByEnv().scope],
      account: this.authService.instance.getAllAccounts()[0]
    };
    this.authService.acquireTokenSilent(accessTokenRequest).subscribe((accessTokenReponse) => {
      if (accessTokenReponse != null) {
        const accessToken = accessTokenReponse.accessToken;
        this.getClaimsData(accessToken);
      }
    });   
  }

  generateCancelPaylaod(): CancelPendingEmail {
    const payload: CancelPendingEmail = {
      federation_id: this.federation_id,
      account_id: this.account_id,
      region_id: this.region_id,
      username: this.username
    };
    return payload;
  }

  getClaimsData(accToken: string): void {
    this.claimPayload = atob(accToken.split('.')[1]);
    if (this.claimPayload) {
      this.federation_id = JSON.parse(this.claimPayload)?.extension_FederationId;
      this.username = JSON.parse(this.claimPayload)?.username;
      this.account_id = JSON.parse(this.claimPayload)?.extension_PrimaryAccountId;
      this.region_id = JSON.parse(this.claimPayload)?.extension_RegionId;
      this.storageService.setSession("federationId", this.federation_id);   
      this.oldEmail = JSON.parse(this.claimPayload)?.email;
      this.setUserFlow();
      if (this.token) {
        this.validateConfirmEmailToken();
      } else {
        this.getPendingEmail();
      }
    }
  }

  returnToCloudLogin(): void { 
    this.authService.logoutRedirect();
  }

  getPendingEmail(): void {
    this.accountManagerService.getPendingEmail(this.federation_id).subscribe({
      next: (data: GetPendingEmailResponse) => this.handleGetPendingEmailResponse(data),
      error: () => this.showApiErrors()
    });
  }

  handleGetPendingEmailResponse(data: GetPendingEmailResponse): void {
    if (data.isEmailChangePending) {
      this.oldEmail = this.oldEmail;
      this.newEmail = data.pendingEmail;
      this.emailChangeString.email_title = this.emailChangeString.cancel_email_title;
      this.emailChangeString.email_message = this.emailChangeString.cancel_email_text;
      this.isShowForConfirmEmaillbl = false;
      this.isShowEmail = true;
    } else {
      const errorContent = this.setNoPendingEmailContent;
      this.commonHelperService.showApiError(errorContent);
    }
    this.loaderService.hideLoading();
  }

  showApiErrors(): void {
    this.commonHelperService.showApiError(this.setError);
    this.loaderService.hideLoading();
  }
}
